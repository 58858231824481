exports.getColors = () => {
    let allColors = {
        elecColor : [
            '#cd8900',
            '#ff8801',
            '#ffbb33',
            '#ffd737',
            '#fff177',
            '#ff7a1b',
        ],
        actionsColors :[
            '#0066cd',
            '#00cdcd',
            '#00cd67',
            '#00cd01',
            '#66cd00'

        ],
        prodColors : [
            '#e70000',
            '#ff8d1a',
            '#ffd536',
            '#cdcd00',
            '#8dff1a'

        ],
        backgroundColor: [
            'rgba(211,145,36,0.8)',
            'rgba(54, 162, 235,0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',

            'rgba(255,101,52,0.8)',
            'rgba(77,102,235,0.8)',
            'rgba(188,255,100,0.8)',
            'rgba(75, 192, 192,0.8)',

            'rgba(255,24,21,0.8)',
            'rgba(52,19,235,0.8)',
            'rgba(167,164,21,0.8)',
            'rgba(63,192,99,0.8)',

            'rgba(255,72,94,0.8)',
            'rgba(168,64,235,0.8)',
            'rgba(126,97,255,0.8)',
            'rgba(79,192,77,0.8)',

            'rgba(255,7,141,0.8)',
            'rgba(72,35,235,0.8)',
            'rgba(66,255,199,0.8)',
            'rgba(65,192,77,0.8)',

            'rgba(55,0,255,0.8)',
            'rgba(209,235,107,0.8)',
            'rgba(243,255,79,0.8)',
            'rgba(192,60,148,0.8)',

            'rgba(255,3,0,0.8)',
            'rgba(235,0,211,0.8)',
            'rgba(3,255,3,0.8)',
            'rgba(88,61,2,0.8)'
        ],
        bordercolor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',

            'rgba(255,101,52,1)',
            'rgba(77,102,235,1)',
            'rgba(188,255,100,1)',
            'rgba(75, 192, 192, 1)',

            'rgba(255,24,21,1)',
            'rgba(52,19,235,1)',
            'rgba(167,164,21,1)',
            'rgba(63,192,99,1)',

            'rgba(255,72,94,1)',
            'rgba(168,64,235,1)',
            'rgba(126,97,255,1)',
            'rgba(79,192,77,1)',

            'rgba(255,7,141,1)',
            'rgba(72,35,235,1)',
            'rgba(66,255,199,1)',
            'rgba(65,192,77,1)',

            'rgba(55,0,255,1)',
            'rgba(209,235,107,1)',
            'rgba(243,255,79,1)',
            'rgba(192,60,148,1)',

            'rgba(255,3,0,1)',
            'rgba(235,0,211,1)',
            'rgba(3,255,3,1)',
            'rgba(88,61,2,1)'
        ]
    }
    return allColors;
}