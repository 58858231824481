<template>
  <div class="header">
    <h5 style="width: 60%;color: lightgrey" ><strong>Impactomètre carbone du <span style="color:#6887ff">numérique</span></strong></h5>
    <img src="../../assets/NewlogoI3S_0.png" alt="logo-i3S" width="150">
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

.header{
  display: flex;
  justify-content: space-between;
  height: 55px;
  text-align: left;
  align-items: center;
  border-radius: 7px;
  margin-bottom: 30px;
  margin-top: 40px;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 275px;
}
h2{
  margin-left: 10px;
}

</style>