<template>
  <div id="footer-wrapper">
    <h5>Développé par Alexis Mérienne</h5>
    <a href="https://github.com/AlexisMerienne/calculateur-ges-num">Code source</a>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
#footer-wrapper{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
}
</style>